@use 'base/variables' as v;
@use 'base/mixins' as m;

.formatoHero { 
    width: 100%; 
    height: 100vh;
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,center,0,column);

    .fondo_hero {
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 

        opacity: 0;
        filter: brightness(0%);
        transition: all 1.25s ease-in-out;

        img {
            object-fit: cover;
            height: 100%;
            object-position: 50% 50%;
        }

    }

    .banner_visible {
        opacity: 1;
        filter: brightness(100%);
    }

}


.clsBefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
}

.formatoHero_Robot {
    position: absolute;
    left: 0%;
    top: 50%;

    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    text-align: left;

    @include m.mixFlex(flex,center,center,wrap,row);

    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
    };

    .rbt-hero-titulo {
        width: 46%;
        padding: 3rem;

        @include m.mixMQ(v.$tablet) {
            width: 90%;
        };

        @include m.mixMQ(v.$movil) {
            padding: 1rem;
        };

        h1 {
            margin-bottom: 10px ;
            text-shadow: v.$box-shadow;

            @include m.mixMQ(v.$movil) {
                font-size: 3rem;
            };
            @include m.mixMQ(v.$movilSM) {
                font-size: 2.5rem;
            };
            
            span {
                color: v.$color1;
                font-weight: 800; 
                text-shadow: v.$box-shadow2;
            }    
        }
    
        h2 {
            font-size: 3rem;
            color: v.$color1;
            
            @include m.mixMQ(v.$movil) {
                font-size: 2rem;
            };

            @include m.mixMQ(v.$movil414) {
                font-size: 1.8rem;
            };

        }
    
        p {
            font-family: v.$fuente_sec;
            font-size: v.$titParrafo + .3rem;
            margin-top: 0; 
            color: v.$blanco;
    
            @include m.mixMQ(v.$deskXL) {
                font-size: v.$titParrafo + .2rem;            
            };
            
            @include m.mixMQ(v.$tablet) {
                font-size: v.$titParrafo - .3rem;            
            };

            @include m.mixMQ(v.$movil) {
                font-size: v.$titParrafo - .5rem; 
            };

        }
    
        a {
            width: 150px;
            text-align: center;
        }
    
    }

    .youtube-container {
        width: 48%;

        @include m.mixMQ(v.$tablet) {
            margin-top: 5rem;

            width: 95%;
            padding-bottom: 54.24%; /* 16:9 Aspect Ratio */
    
        };
    }


}
