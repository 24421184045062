@use 'base/variables' as v;
@use 'base/mixins' as m;


.sec_videos_robot {
    width: 95%;
    margin: 3rem auto;

    @include m.mixFlex(flex,space-between,center,wrap,row);

    @include m.mixMQ(v.$tablet) {
        margin-top: 0;
    };

    @include m.mixMQ(v.$movilSM) {
        height: auto;
        margin-bottom: 0;
    };

}

.seccion-robodescrip {

    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);
    overflow: hidden;

    .descrip-rbt-container {
     
        width: 90%;
        height: auto;
        position: relative;
        @include m.grid(2,2rem);
        z-index: 100;

        @include m.mixMQ(v.$tablet) {
            @include m.grid(1,2rem);            
        };

        .descrip_rbt_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;

            margin: 1rem;

            .content {
                width: 100%;
                padding: 3rem;
                transition: 0.5s;

                @include m.mixMQ(v.$movilSM) {
                    padding: 1.5rem;
                };

                i {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                    font-size: 5em;
                    color: v.$color1;
                    pointer-events: none;
                    transition: 0.5s;    
                    z-index: -1;

                    @include m.mixMQ(v.$movil) {
                        font-size: 3.5em;
                    };
                }
                
                h4 {
                    z-index: 1000;
                    margin-bottom: 1.5rem;
                    color: v.$blanco;
                    font-weight: bold;
                }

                p {
                    color: v.$blanco;
                }

                audio {
                    margin-top: 2.5rem;
                }
            }
        }            

    }
}

.seccion-problema {

    width: 100%;
    height: 90vh;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/mesero_estresado.jpg');

    }

    .problema-container {

        width: 95%;
        max-width: 1200px;
        margin: auto;
        text-align: center;
        color: v.$blanco;

        .pain-points {
            display: flex;
            justify-content: space-around;
            margin: 5rem;    

            @include m.mixMQ(v.$movil) {
                margin: 5rem auto;
            };

            @include m.mixMQ(v.$movil414) {
                flex-direction: column;
            };
            
            .pain-point {

                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.5);
                border-left: 1px solid rgba(255, 255, 255, 0.5);
                background: rgba(255,0,0, 0.5);
                border-radius: 10px;
                padding: 3rem;
    
                @include m.mixMQ(v.$movil600) {
                    margin: auto 2rem;
                };
                @include m.mixMQ(v.$movil414) {
                    margin: 2rem;
                };
    
                i {
                    font-size: 5rem;
                    color: v.$blanco;
                    margin-bottom: 1rem;
                }

                p {
                    color: v.$blanco;
                    font-weight: bold;
                    font-size: 2rem;
                }
            }

        }    

        .statistics {
            // font-size: 2.5rem;
            text-align: center;
            color: v.$blanco;
            margin-top: 2rem;
            font-weight: bolder;

            @include m.mixMQ(v.$movil414) {
                width: 90%;
                margin: auto;
            };
        }    

        .rhetorical-questions {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: v.$blanco;
            font-size: 2.2rem;
        }    

    }
}

.seccion-solucion {

    width: 100%;
    height: 90vh; 
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    @include m.mixMQ(v.$tablet) {
        height: auto;
    };

    .solution-container {

        width: 90%;
        max-width: 1200px;
        height: 75%;
        padding: 3rem;
        text-align: center;

        @include m.mixMQ(v.$tablet) {
            width: 95%;
            padding: 2rem;
            margin-bottom: 5rem;
        };

        .solution-points {
            display: flex;
            justify-content: space-around;
            margin: 4rem auto;

            @include m.mixMQ(v.$movil600) {
                margin: 3rem auto;
            };

            @include m.mixMQ(v.$movil414) {
                flex-direction: column;
            };

            .sol-punto {
                width: 20rem;
                height: 15rem;
                background-color: v.$color1;
                padding: 1rem;
                border-radius: 15px;
                @include m.mixFlex(flex,center,center,wrap,false);

                @include m.mixMQ(v.$tablet) {
                    width: 18rem;
                    margin: auto 1.5rem;
                };

                @include m.mixMQ(v.$movil414) {
                    width: 90%;
                    height: 10rem;
                    margin: 1rem auto;
                };

                p {
                    width: 100%;
                    font-size: 2rem;
                    // text-align: center;
                    color: v.$blanco;
                    font-weight: bold;
                }
            }
        }        

        .solution-description {
            margin-top: 20px;
            font-size: 18px;
        } 
        
        .solution-cta {
            display: inline-block;
            background-color: #007BFF; /* Puedes cambiar el color */
            color: white;
            padding: 10px 20px;
            margin-top: 20px;
            text-decoration: none;
        }        
    }

}

.seccion-beneficios-robot {

    width: 100%;
    height: 100vh;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);
    color: v.$blanco;
    text-align: center;

    @include m.mixMQ(v.$movil600) {
        height: auto; 
    };

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_beneficios.jpg');
    }

    .beneficios-container {

        max-width: 1200px;
        margin: auto;
        text-align: center;

        .beneficios-points {
            margin: 5rem; 

            @include m.grid(4,3rem);

            @include m.mixMQ(v.$tablet) {
                @include m.grid(3,2rem);                
            };

             @include m.mixMQ(v.$movil600) {
                @include m.grid(2,2rem);                                
             };

             @include m.mixMQ(v.$movil) {
                margin: 5rem 2rem; 
             };

             @include m.mixMQ(v.$movil414) {
                @include m.grid(1,1rem);                 
             };

            .beneficios-point {

                padding: 3rem;
                box-shadow: v.$box-shadow4;

                @include m.mixMQ(v.$movil414) {
                    @include m.mixFlex(flex,left,center,wrap,row);
                    padding: 2rem;
                };
    
                i {
                    font-size: 5rem;
                    color: v.$color1;
                    margin-bottom: 1rem;
                    animation: moveUpDown 2s infinite ease-in-out;

                    @include m.mixMQ(v.$movil414) {
                        width: 15%;
                        font-size: 3rem;
                        margin-right: 1rem;
                    };

                }

                p {
                    color: v.$blanco;
                    font-size: 2rem;

                    @include m.mixMQ(v.$movil414) {
                        width: 82%;
                        text-align: left;
                    };

                    @include m.mixMQ(v.$movilSM) {
                        width: 75%;
                        font-size: 1.5rem;
                    };

                }
            }

        }    

        .statistics {
            // font-size: 2.5rem;
            text-align: center;
            color: v.$blanco;
            margin-top: 2rem;
            font-weight: bolder;
        }    

        .rhetorical-questions {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: v.$blanco;
            font-size: 2.2rem;
        }    

    }
}

.seccion-rbt-proceso {

    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    .proceso-rbt-container {
     
        width: 90%;
        max-width: 1200px;
        height: auto;
        position: relative;
        z-index: 100;
        margin: 5rem auto;

        @include m.grid(2,3rem);

        @include m.mixMQ(v.$movil) {
            @include m.grid(1,2rem);            
        };

        .proceso_rbt_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;
            width: 100%;

            .content {
                padding: 3rem;
                transition: 0.5s;
                text-align: left;
                width: 100%;

                .card-titulo {

                    @include m.mixFlex(flex,left,center,nowrap,row);
                    margin-bottom: 1rem;

                    .titulo-icono {
                        background-color: v.$blanco;
                        border-radius: 50%;
                        // border: 3px solid v.$blanco;
                        width: 5rem;
                        height: 5rem;
                        @include m.mixFlex(flex,center,center,false,false);

                        i {
                            font-size: 2.6rem;
                            color: v.$color1;
                            pointer-events: none;
                            transition: 0.5s;    
                        }
                            
                    }

                    h4 {
                        z-index: 1000;
                        margin-left: 1rem;
                        margin-bottom: 0;
                        color: v.$blanco;
                        text-shadow: v.$box-shadow2;
                        font-weight: bold;
                    }    
    
                }

                

                p {
                    color: v.$blanco;
                }

                audio {
                    margin-top: 2.5rem;
                }
            }
        }
    }

}

.seccion-precio-robot {

    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 8rem;
    @include m.mixFlex(flex,center,center,false,false);

    @include m.mixMQ(v.$movil) {
        height: auto; 
    };

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%), url('../img/robot_precio.jpg');
    }

    .rbt-container {

        width: 90%;
        max-width: 1200px;
        margin: auto;
        color: v.$blanco;

        .rbt-card-ctnr {

            width: 100%;
            margin-top: 3rem;
            overflow: hidden;
            @include m.mixFlex(flex,left,center,nowrap,row);

            @include m.mixMQ(v.$movil600) {
                flex-direction: column;
            };

            .rbt-card-img {

                width: 35%;                

                @include m.mixMQ(v.$movil600) {
                    width: 100%;
                };

                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.75);
                border-left: 1px solid rgba(255, 255, 255, 0.75);
                background: rgba(255,255,255, 0.75);
                border-radius: 10px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .rbt-caracteristicas {

                    width: 100%;
                    color: v.$negro;
                    padding: 2rem;

                    .lista_elementos {
                        margin-top: .5rem!important;
                    }

                }


            }

            .rbt-card-detalles {

                width: 65%;
                padding: 3rem;

                @include m.mixMQ(v.$movil600) {
                    margin-top: 2rem;
                    width: 100%;
                };

                @include m.mixMQ(v.$movil414) {
                    padding: 1rem;
                };

                @include m.mixFlex(flex,left,center,nowrap,column);
    
                .rbt-modelo {

                    width: 100%;

                    h5 {
                        font-weight: bold;

                        span {
                            font-weight: 400;
                        }

                        small {
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: v.$color1;
                        }
                    }

                    .precio-lista {
                        font-weight: bold;
                        font-style: italic;
                        text-decoration: line-through red;
                        
                    }

                }

                .rbt-precio {

                    width: 100%;
                    margin-top: 1.5rem;

                    h3 {
                        font-weight: bold;

                        span {
                            font-size: 1.5rem;
                            color: v.$color1;
                        }
                    }

                    .rbt-precio-condiciones {

                        padding-left: 4rem;
                        margin-bottom: 1rem;
                        
                        font-weight: normal!important;
                        font-style: italic;

                        i {
                            color: yellow;
                        }
                    }

                    .lista_elementos_white {
                        margin-top: .5rem!important;
                    }

                }

            }

        }

    }
}

.seccion-rbt-negocios {


    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    .negocios-rbt-container {
     
        width: 90%;
        height: auto;
        margin-top: 3rem;

        @include m.mixFlex(flex,space-around,center,wrap,row);

        .negocios-card {

            width: 300px;
            margin: 15px;
            border: 1px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            box-shadow: v.$box-shadow;

            @include m.mixMQ(v.$desk) {
                width: 250px;
                
            };

            @include m.mixMQ(v.$tablet) {
                width: 300px;
            };

            @include m.mixMQ(v.$movil600) {
                width: 100%;
            };

            img {
                width: 100%;
                height: auto;
            }
              
            .negocios-card-content {

                padding: 1.5rem;
                background: v.$blanco;

            }
        }
    }

}

.secDespedidaWeb {

    margin: 0 auto;
    @include m.mixFlex(flex,center,0,wrap,column);

    .titulo {
        width: 90%;
        max-width: 1200px;
        margin: auto;

        h4 {
            margin-top: 2rem;

            span {
                color: v.$color1;
            }
        }

        p {
            @include m.mixMQ(v.$desk) {
                font-size: 1.6rem;
            };

            @include m.mixMQ(v.$tablet) {
                font-size: 1.4rem;
            };

        }

        .despedida_contacto {
            margin-top: 2rem;

            a {
                width: 20rem;
                margin: 1rem;

                @include m.mixMQ(v.$tablet) {
                    width: 15rem;
                };
            }
        }

    }

    .imgDespWeb {
        width: 100%;
        z-index: 2000;

        img {
            height: 100%;
            // width: 100%;
            object-fit: contain;
        }
    }

}