@use 'variables' as v;
@use 'mixins' as m; 

// @import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;600;700&family=Roboto:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

html { 
    font-size: 62.5%; 
    box-sizing: border-box; 
    scroll-padding-top: 0rem; 
    scroll-behavior: smooth;  
}  
 
*,    
*:before,      
*:after {   
    box-sizing: inherit;
    margin: 0;        
}  
 
body {  
    font-family: v.$fuente_principal;  
    font-size: 16px;  

    &::-webkit-scrollbar {   
        width: 8px;
        height: 8px;   
    }

    &::-webkit-scrollbar-thumb {
        background: v.$color1;     
        border-radius: 5px;  
    } 
}  

.body-noscroll {
    overflow-y: hidden;
    overflow-x: hidden; 
}

p {
    font-size: v.$descCon;      
    color: v.$negro; 
    line-height: 1.20;    
    margin: .25rem 0; 
    font-family: v.$fuente_sec;   
} 

a {
    text-decoration: none;
}

h1, h2, h3, h4 {
    margin: 0 0 1rem 0;
    font-family: v.$fuente_principal;
}

h1 {
    font-size: v.$titHero;  

    @include m.mixMQ(v.$desk) {
        font-size: v.$titHero - 1rem;
    };

    @include m.mixMQ(v.$movil414) {
        font-size: v.$titHero - 2.5rem;
    };

    @include m.mixMQ(v.$movilSM) {
        font-size: v.$titHero - 2.8rem;
    };

}

h2 {
    font-size: v.$titSec;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titSec - 1rem;
    }        

    @include m.mixMQ(v.$desk) {
        font-size: v.$titSec - 1.2rem;
    }        

    @include m.mixMQ(v.$movil) {
        font-size: v.$titSec - 1.3rem;        
    };

}

h3 {
    font-size: v.$titulos;
}
h4 {
    font-size: v.$titParrafo;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titParrafo - .3rem;        
    };

}

h5 {
    font-size: v.$titH5;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titH5 - .3rem;        
    };

    @include m.mixMQ(v.$movil) {
        font-size: v.$titH5 - .6rem;        
    };
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block; 
}

.iconos {
    @include m.mixIcono(4rem, 1.7rem);
    background: v.$degradadoRed;
    color: v.$blanco; 

    transition: transform .3s ease-in;
    
    &:hover {
        transform: rotate(360deg);
    }    
}

.icono_peq {
    @extend .iconos;

    @include m.mixIcono(2rem, 1.2rem);
    margin-right: .5rem;
}

.iconos-blanco {
    @extend .iconos;

    background: v.$blanco;
    color: v.$color2;
}

.iconos-verde {
    @extend .iconos;

    background: v.$degradado4;
    color: v.$blanco; 
}

.seccion {
    padding: v.$sepSeccion 0;
}

.seccionGris {
    @extend .seccion;
    background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten;
}

.seccionGrisOscuro {
    @extend .seccion;
    background: v.$color4;
}

// Titulo de cada sección 
.titulo {
    margin: 0 auto;
    text-align: center;
    width: 85%;

    h2 {
        text-transform: uppercase;

        span { 
            font-weight: 900;
            color: v.$color1;
            text-shadow: v.$box-shadow4;
        }
    } 

    p {
        font-size: v.$descSec;
        font-weight: bold;

        @include m.mixMQ(v.$tablet) {
            font-size: v.$descSec - .1rem;
        };
        @include m.mixMQ(v.$movil) {
            font-size: v.$descSec - .3rem;
        };

    }

    small {
        font-size: 2rem;
        color: v.$color1;
        font-weight: bold;
        margin: 1.5rem 0;
        display: block;
    }

}

.tituloWhite {
    @extend .titulo;

    h2, p {
        color: v.$blanco;

        span {
            color: v.$color1;
        }
    }
}

.txtWhite {
    color: v.$blanco;
}

.boton {
    margin: 1rem;
    display: inline-block;
    font-size: v.$descCon;
    color: v.$blanco;
    border-radius: 50px;
    cursor: pointer;
    padding: .8rem 2rem;
    background: v.$color1;
    transition: all 0.4s ease-in;
    border: 2px solid v.$color1;

    &:hover {
        box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
        border: 2px solid v.$color1;
        color: v.$blanco;
    }
    
    &:active {
        transform: scale(0.98);
    }    

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$descCon - 0.2rem;
    };
}

.botonVerde {
    @extend .boton; 
    background: v.$color6;
    border: 2px solid v.$color6;

    &:hover {   
        color: v.$color6;
        border: 2px solid v.$color6;
    }
}

.botonGris {    
    @extend .boton;
    background: v.$color3; 
    border: 2px solid v.$color3;

    &:hover {
        color: v.$blanco;
        border: 2px solid v.$color1;
    }
}

// Clase del botón que moverse al inicio de la pantalla
.botInicio {
    position: fixed;
    left: 150px;
    bottom: 32px;
    z-index: 10000; 
}

.widgetWP {
    position: fixed;
    width: 165px;
    left: -30px;
    bottom: 20px;
    z-index: 10000;

    a {
        width: 100%;
        text-align: right;
    }
}

.bannerPub {
    padding: 0;
    width: 80%; 
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;

}

.tit-enorme {    
    font-size: 100px;

    @include m.mixMQ(v.$desk) {
        font-size: 80px;        
    };

    @include m.mixMQ(v.$tablet) {
        font-size: 60px;
    };
    @include m.mixMQ(v.$movilSM) {
        font-size: 45px;
    };
}

.font-bold {
    font-weight: bold;
}

.text-derecha {
    text-align: right;
}

.text-izq {
    text-align: left;
}

.anim-mouse {
    width: 100%;
    position: absolute;
    bottom: 6rem;
    display: flex;
    justify-content: center;
    padding-right: 3rem;

    .mouse {
        @include m.bgGradient;
        position: relative;
        width: v.$widthMouse;
        height: v.$heightMouse;
        border-radius: 100px;
        background-size: 100% 200%;
        animation: 
        colorSlide v.$animDuration linear infinite,
        nudgeMouse v.$animDuration ease-out infinite;

        @include m.mixMQ(v.$tablet) {
            width: v.$widthMouseSM;
            height: v.$heightMouseSM;                
        };
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
        &:before {
            width: v.$widthMouse - v.$borderMouse;
            height: v.$heightMouse - v.$borderMouse;
            background-color: v.$colorBg;
            border-radius: 100px;

            @include m.mixMQ(v.$tablet) {
                width: v.$widthMouseSM - v.$borderMouseSM;
                height: v.$heightMouseSM - v.$borderMouseSM;                    
            };
        }
        &:after {
            background-color: v.$colorOutline;
            width: v.$sizeTrackball; 
            height: v.$sizeTrackball;
            border-radius: 100%;
            animation: trackBallSlide v.$animDuration linear infinite;
        }
    }
}

.mouse-b2 {
    bottom: 2rem;
}


// ! SECCIÓN DE PREGUNTAS FRECUENTES
.genFaqs {
    padding-top: 6rem;
    padding-bottom: 1rem;

    .disFaqs_container { 
        margin: 2rem auto;
        padding: 2rem;

        @include m.mixMQ(v.$movilSM) {
            padding: 2rem 0;
        };
    }

    .accordion {

        @include m.mixMQ(v.$movilSM) {
            width: 90%;
            margin: 0 auto;

        };

        .accordion-item {
            border-bottom: 1px solid v.$color5;

            button[aria-expanded='true'] {
                border-bottom: 1px solid v.$color1;
            }
        }

        button {
            position: relative;
            display: block;
            text-align: left;
            width: 100%;
            padding: 2em 0;
            font-size: 1.5rem;
            font-weight: 400;
            border: none;
            background: none;
            outline: none;
            font-family: v.$fuente_principal;

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;
            };

            &:hover,
            &:focus {
                cursor: pointer;
                color: v.$color1;

                &::after {
                    cursor: pointer;
                    color: v.$color1;
                    border: 1px solid v.$color1;
                }
            }

            .accordion-title {
                padding: 1em 1.5em 1em 0;
            }

            .iconFaq {
                display: inline-block;
                position: absolute;
                top: 18px;
                right: 0;
                width: 22px;
                height: 22px;
                border: 1px solid;
                border-radius: 22px;
                color: v.$color3;

                &::before {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 9px;
                    left: 5px;
                    width: 10px;
                    height: 2px;
                    background: currentColor;
                }

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 5px;
                    left: 9px;
                    width: 2px;
                    height: 10px;
                    background: currentColor;
                }
            }
        }

        button[aria-expanded='true'] {
            color: v.$color1;

            .iconFaq {
                &::after {
                    width: 0;
                }
            }

            +.accordion-content {
                opacity: 1;
                max-height: 9em;
                transition: all 200ms linear;
                will-change: opacity, max-height;

                @include m.mixMQ(v.$movilSM) {
                    max-height: 12em;
                };
            }
        }

        .accordion-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 200ms linear, max-height 200ms linear;
            will-change: opacity, max-height;

            p {
                font-size: 1.5rem;
                font-weight: 300;
                margin: 2em 0;

                @include m.mixMQ(v.$movil) {
                    font-size: 1.2rem;
                };
            }
        }
    }
}

.lista_elementos {
    width: 100%;
    height: auto;
    margin: 1.5rem 0;
    z-index: 500;
    overflow: hidden;    

    .lista_elemento {
        @include m.mixFlex(flex,0,center,nowrap,0);
        margin: 10px 0;

        i {
            font-size: 1.5rem;
            margin-top: 2px;
            margin-right: .8rem;
        }
    
        .color_ver {
            color: v.$color6;
        }

        .color_blanco {
            color: v.$blanco;
        }
        p {
            margin: 0;
            line-height: 1.20;
            font-size: 1.5rem;
            text-align: left!important;
            width: 100%;
        }

        a {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &:link {
                color: v.$blanco;
            }
              
            &:visited {
                color: #0a07b5;
            }            
        }

    }

    small {
        display: block;
        margin-top: 2rem;
        color: v.$blanco;
    }

}

.color_rojo {
    color: v.$color1;
}

.lista_elementos_white {
    @extend .lista_elementos;

    margin: 2rem;

    p {
        color: v.$blanco;
    }
}

.filtro-negro { 
    position: absolute;
    width: 100%;
    height: 100%; 
    z-index: -5;
    top: 0;
    left: 0;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        content: "";
        background-image: linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.75) 100%);

    }

    img {
        object-fit: cover;
        height: 100%;
    }
}

.filtro-sinfondo {
    @extend .filtro-negro;

    &::before {
        background: none;        
    }    
}

.filtro-negro-border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px 10px 0 0;
    }    
}


.filtro-negro-deg2 {
    @extend .filtro-negro;

    &::before {
        background-image: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%);
    }    
}

.filtro-negro-deg2border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}



.cls_pub {
    max-height: 65vh;
    overflow: hidden;
}

.pub_content {
    
    .pub-logo {
        width: 35%;
        margin-bottom: 3rem;

        @include m.mixMQ(v.$movil) {
            margin-bottom: 1rem;
        };

        @include m.mixMQ(v.$movilSM) {
            display: none;
        };
        
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .pub-textos {
        // width: 80%;

        h3 {
            font-size: 3rem;

            @include m.mixMQ(v.$desk) {
                font-size: 2.75rem;
            };
            @include m.mixMQ(v.$tablet) {
                font-size: 2rem;
            };
            @include m.mixMQ(v.$movil) {
                font-size: 1.5rem;
                margin-bottom: 3px;
            };

        }

        p {
            font-size: 2.5rem;

            @include m.mixMQ(v.$desk) {
                font-size: 2rem;
            };
            @include m.mixMQ(v.$tablet) {
                font-size: 1.75rem;
            };
            @include m.mixMQ(v.$movil) {
                font-size: 1.25rem;
            };
        }

        button {
            @include m.mixMQ(v.$movil) {
                margin-top: 1rem!important;
                font-size: 1.25rem!important;
                padding: 5px 10px;
            };
        }
    }
}

.youtube-container {
    position: relative;
    width: 48%;
    padding-bottom: 27.12%; /* 16:9 Aspect Ratio */ 
    margin-top: 3rem;

    @include m.mixMQ(v.$tablet) {
        width: 95%;
        padding-bottom: 54.24%; /* 16:9 Aspect Ratio */
        margin: 0 auto 4rem;
    };

    .youtube-video {
        position: absolute;            
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            box-shadow: v.$box-shadow;
        }
    
    }        

    .youtube-portada {
        position: absolute;            
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;

        img {
            position: absolute;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            box-shadow: v.$box-shadow;

            @include m.mixMQ(v.$tablet) {
                height: 100%;
            };
        }
    
    }        

}    

.play {
    position: absolute;
    cursor: pointer;
    width: 70px!important;
    height: 70px!important;
    display: block;  
    transition: .5s;
    border-radius: 50%;

    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%);

    -webkit-animation: ripple 2.5s linear 1s infinite;
    animation: ripple 2.5s linear 1s infinite;

    img {
        width: 100%;
        transition: width 0.5s;
    }
    
    &:hover {
        background: v.$color1;
    }            
}        

.banda_color {
    width: 100%;
    background: v.$degradadoRed;
    height: 25vh;
    @include m.mixFlex(flex,center,center,fxWr,fxFd);

    @include m.mixMQ(v.$movil) {
        height: 50vh;
    };

    .banda_content {
        width: 90%;
        max-width: 1200px;
        color: v.$blanco;
        text-align: center;

        p {
            color: v.$blanco;
        }
    }
}

.efecto-vidrio {

    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.75);
    border-left: 1px solid rgba(255, 255, 255, 0.75);
    background: rgba(0,0,0, 0.50);
    border-radius: 10px;

}

.efecto-fondo-fijo {

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

    }

}

.mensaje-navega {
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translateX(-50%);

    h4 {
        @include m.mixMQ(v.$movilSM) {
            font-size: 1.3rem;
        };
    }

    i {
        font-size: 3rem;
        animation: moveUpDown 2s infinite ease-in-out;            
    }
}

// ! SECCIÓN PUBLICIDAD
// ! =================================================================

.secBannerPub { 
    max-width: 1200px;
    margin: 3rem auto 6rem;
    // margin-bottom: 6rem;

    @include m.mixMQ(v.$deskXL) {
        width: 90%;
    };

    @include m.mixMQ(v.$movil) {
        width: 95%;
    };
}

.fto-img-pub {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    // filter: brightness(0.5);    

}

// ! =================================================================